import React from "react";
import Layout from "../components/layout.component";
import {useConfig} from "../hooks/use-config.hook";
import {translationHelper} from "../helpers/translation.helper";
import {useTranslation} from "react-i18next";
import {GatsbySeo} from "gatsby-plugin-next-seo";
import {TranslationKeys} from "../interfaces/translation-keys.enum";
import {useChat} from "react-live-chat-loader";

const ContactUs = (): JSX.Element => {

    const [state, loadChat] = useChat();

    const openBeacon = () => {
        loadChat({open: true})
    };

    const config = useConfig();

    const {t, i18n} = useTranslation();

    let organizerTitle = translationHelper(
        config.organizer.title,
        i18n.language
    );

    const organizerName = translationHelper(
        config.organizer.name,
        i18n.language
    )
    if (organizerName !== organizerTitle) {
        organizerTitle = `${organizerTitle} (${organizerName})`
    }

    const address = config.organizer.address;

    return (
        <Layout>
            <GatsbySeo
                title={`Ota yhteyttä - ${translationHelper(
                    config.title,
                    i18n.language
                )}`}
            />
            <div className="card w-full overflow-hidden mb-4 grid grid-cols-4">
                {/*address && <div className="col-span-4">
                    <iframe
                        className="border-0 w-full h-96"
                        loading="lazy"
                        allowFullScreen
                        src={`https://maps.google.com/maps?f=q&source=s_q&z=15&ie=UTF8&output=embed&q=${address.address_line1},${address.city}`}
                    />
                </div>*/}
                <div
                    className="col-span-4 lg:col-span-2 bg-opacity-25 border-b lg:border-b-0 lg:border-r border-gray-200">
                    <div className="prose prose-sm prose-headings:mb-0 prose-p:mt-0 p-4">
                        <h1 className="text-lg font-bold">
                            {organizerTitle}
                        </h1>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: translationHelper(
                                    config.organizer.customer_service_info,
                                    i18n.language
                                ),
                            }}
                        />
                        <div className="mt-2 text-base text-gray-700">
                            {config.organizer.business_id && (
                                <span className="block">
                                    <b>{t(TranslationKeys.COMMON_ORGANIZER_BUSINESS_ID)}:</b> {config.organizer.business_id}
                                </span>
                            )}
                            {/*address?.address_line1 && (
                                <span className="block">
                                    {address.address_line1}
                                </span>
                            )*/}
                            {/*address?.address_line2 && (
                                <span className="block">
                                    {address.address_line2}
                                </span>
                            )*/}
                            {/*address?.postal_code && address?.city && (
                                <span className="block">
                                    {address.postal_code},{" "}
                                    {address.city}
                                </span>
                            )*/}
                        </div>
                        <button
                            onClick={() => openBeacon()}
                            type="button"
                            className="btn btn-primary mt-4"
                        >
                            {t(TranslationKeys.COMMON_CONTACT_TECHNICAL_SUPPORT)}
                        </button>
                    </div>
                </div>
                <div className="col-span-4 lg:col-span-2">
                </div>
            </div>
        </Layout>
    );
};

export default ContactUs;
